<template>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page">Общество с ограниченной ответственностью
        «ТехУспех»</h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">

            <table class="info-page">
              <tr>
                <td>Полное наименование организации</td>
                <td>Общество с ограниченной ответственностью
                  «ТехУспех»
                </td>
              </tr>
              <tr>
                <td>Краткое наименование организации</td>
                <td>ООО «ТехУспех»</td>
              </tr>
              <tr>
                <td>ИНН</td>
                <td>7724369691</td>
              </tr>
              <tr>
                <td>КПП</td>
                <td>772601001</td>
              </tr>
              <tr>
                <td>ОГРН</td>
                <td>1167746577250</td>
              </tr>
              <tr>
                <td>Код ИФНС</td>
                <td>7724</td>
              </tr>
              <tr>
                <td>Юридический адрес</td>
                <td>117556, РФ, г. Москва, Нахимовский проспект, д. 1, корп. 2, этаж 1, помещение 1, комната 17.
                </td>
              </tr>
              <tr>
                <td>Фактический адрес</td>
                <td>117405 г. Москва ул. Газопровод д.2А </td>
              </tr>
              <tr>
                <td>Телефон</td>
                <td>8 (499) 651-38-79, 8 (916) 277-10-00
                </td>
              </tr>
              <tr>
                <td>Электронная почта</td>
                <td>ooo.texuspex@gmail.com</td>
              </tr>
              <tr>
                <td>Сайт</td>
                <td>www.texuspex.ru</td>
              </tr>
              <tr>
                <td>ФИО руководителя</td>
                <td>Кондратьев Илья Викторович </td>
              </tr>
              <tr>
                <td>р\сч</td>
                <td>40702810238000115120</td>
              </tr>
              <tr>
                <td>Банк</td>
                <td>ПАО "Сбербанк России" </td>
              </tr>
              <tr>
                <td>БИК</td>
                <td>044525225</td>
              </tr>
              <tr>
                <td>к\сч</td>
                <td>30101810400000000225</td>
              </tr>
              <tr>
                <td>ОКАТО</td>
                <td>45296597000</td>
              </tr>
              <tr>
                <td>ОКТМО</td>
                <td>45926000000</td>
              </tr>
              <tr>
                <td>ОКПО</td>
                <td>03128195</td>
              </tr>
              <tr>
                <td>ОКОГУ</td>
                <td>4210014</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info"
}
</script>

<style scoped>
.info-page{
  font-size: 120%;
  width: 100%;
}
.info-page tr{
  border-bottom: #5e5e5e 1px solid;
  height: 40px;
}
</style>